import React, { useContext, useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import { FaUser, FaFeatherAlt, FaQuestionCircle, FaBook, FaRupeeSign, FaRegHandPointRight, FaBookReader, FaInfoCircle, FaUnlockAlt, FaShieldAlt, FaCog, FaSignOutAlt, FaChartLine, FaShoppingCart, FaLock, FaPen } from "react-icons/fa";

import { UserContext } from '../contexts/UserContext';

import './Nav.css';

function Nav({ searchParam }) {
    const[show, handleShow] = useState(false);
    const[search , setSearch] = useState('');
    const{ user, setUser } = useContext(UserContext);
    const [navVisibility, setNavVisibility] = useState(false);
    const [settingsDropdownVisible, setSettingsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const changeNavVisibility = () => {
        setNavVisibility(!navVisibility);
    }

    const toggleSettingsDropdown = () => {
        setSettingsDropdownVisible(!settingsDropdownVisible);
    }

    const handleSearchChange =(e)=>{
        setSearch(e.target.value);
        searchParam(e.target.value);
      }

    const handleLogout = () => {
        localStorage.removeItem('user');
        setUser(null);
    }
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setSettingsDropdownVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                handleShow(true);
            } else handleShow(false);
        });

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[]);

    //for the signupLogin page
    if (user === null) {
        return (
            <div className={`nav ${show && "nav_black"}`}>
                <div className='container'>
                    <div className='logo'>
                        <NavLink to="/">
                            <img 
                                className='nav_logo'
                                src='https://i.postimg.cc/MTkWjTSx/Biblophile-logo-white.png'
                                alt='biblophile Logo'
                            />
                        </NavLink>
                    </div>
                    <ul>
                        <li>
                            <button className="guest-button" onClick={() => {setUser(0)}}>Guest user</button>
                        </li>
                    </ul>
                </div>
    
            </div>
        )
    }
    else if (user === 0) {  //if the user decides to continue as guest
        return (
            <div className={`nav ${show && "nav_black"}`}>
                <div className='container'>
                    <div className='logo'>
                        <NavLink to="/">
                            <img 
                                className='nav_logo'
                                src='https://i.postimg.cc/MTkWjTSx/Biblophile-logo-white.png'
                                alt='biblophile Logo'
                            />
                        </NavLink>
                        <input
                            className='search_input'
                            type="text"
                            placeholder='Search for books'
                            value={search}
                            required
                            onChange={(e)=> {handleSearchChange(e)}}
                        />
                    </div>
    
                    <input type="checkbox" name="" id="" onClick={() => changeNavVisibility()} />
                    <div className="hamburger-lines">
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>
    
                    <div className={`nav-elements  ${!navVisibility && 'hideNavBar'}`}>
                        <div className="nav-list">
                            <ul className="nav-items">
                                <li>
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about">About</NavLink>
                                </li>
                                <li>
                                    <a href="/">Login</a>
                                </li>
                            </ul>
                            <div className='phone-nav'>
                                <h3 className='phone-nav-title'>Help & Support</h3>
                                <ul className='phone-nav-items'>
                                    <li><a href="https://biblophile.com/policies/terms-of-service.php"><FaShieldAlt size={20} /> Terms of Services</a></li>
                                    <li><a href="https://biblophile.com/policies/privacy-policy.php"><FaLock size={20} /> Privacy Policy</a></li>
                                    <li><a href="https://biblophile.com/policies/refund.php"><FaUnlockAlt size={20} /> Return/Refund Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        )
    }
    else {  //if the user logins
        return (
            <div className={`nav ${show && "nav_black"}`}>
                <div className='container'>
                    <div className='logo'>
                        <NavLink to="/">
                            <img 
                                className='nav_logo'
                                src='https://i.postimg.cc/MTkWjTSx/Biblophile-logo-white.png'
                                alt='biblophile Logo'
                            />
                        </NavLink>
                        <input
                            className='search_input'
                            type="text"
                            placeholder='Search for books'
                            value={search}
                            required
                            onChange={(e)=> {handleSearchChange(e)}}
                        />
                        <div className="dropdown profile-container" ref={dropdownRef}>
                            <span onClick={toggleSettingsDropdown}><FaUser size={30} color='#D17842' /></span>
                            {settingsDropdownVisible && (
                                <ul className="dropdown-menu">
                                    <li><NavLink to="/profile">Reading Journal</NavLink></li>
                                    <li><NavLink to="/subscription">Subscription</NavLink></li>
                                    <li>
                                    <NavLink to="/bookshelf">Bookshelf</NavLink>
                                    </li>
                                    <li><NavLink to="/reviews">My Reviews</NavLink></li>
                                    <li><NavLink to="/notes">My Notes</NavLink></li>
                                    <li><NavLink to="/settings">Account Settings</NavLink></li>
                                    <li onClick={() => handleLogout()} className='logout-text'>
                                        Logout
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
    
                    <input type="checkbox" name="" id="" onClick={() => changeNavVisibility()} />
                    <div className="hamburger-lines">
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>
    
                    <div className={`nav-elements  ${!navVisibility && 'hideNavBar'}`}>
                        <div className="nav-list">
                            <ul className="nav-items">
                                <li>
                                    <a href="/readingStreaks"><FaChartLine size={20} /> Stats</a>
                                </li>
                                <li>
                                    <NavLink to="/orders"><FaShoppingCart size={20} /> Orders</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/about"><FaInfoCircle size={20} /> About</NavLink>
                                </li>
                            </ul>
                            <div className='phone-nav'>
                                <h3 className='phone-nav-title'>Profile</h3>
                                <ul className='phone-nav-items'>
                                    <li><NavLink to="/profile"><FaUser size={20} /> Reading Journal</NavLink></li>
                                    <li>
                                    <NavLink to="/bookshelf"><FaBook size={20} /> Bookshelf</NavLink>
                                    </li>
                                    <li><NavLink to="/subscription"><FaRupeeSign size={20} /> Subscription</NavLink></li>
                                    <li><NavLink to="/reviews"><FaPen size={20} /> My Reviews</NavLink></li>
                                    <li><NavLink to="/notes"><FaFeatherAlt size={20} /> My Notes</NavLink></li>
                                    <li><NavLink to="/settings"><FaCog size={20} /> Account Settings</NavLink></li>
                                    <li onClick={() => handleLogout()}><FaSignOutAlt size={20} /> Logout</li>
                                </ul>
                                <h3 className='phone-nav-title'>Help & Support</h3>
                                <ul className='phone-nav-items'>
                                    <li><a href="https://biblophile.com/policies/faq.php"><FaQuestionCircle size={20} /> FAQs</a></li>
                                    <li><a href="https://forms.gle/abqJbuW5UxducZst7"><FaRegHandPointRight size={20} /> Request a book</a></li>
                                    <li><a href="https://forms.gle/JMbjx6iEn4HVZvvS6"><FaBookReader size={20} /> Rent/Sell your book</a></li>
                                    <li><a href="https://forms.gle/1RgWuAXJamudCRmB7"><FaRegHandPointRight size={20} /> Suggestions/Feedback</a></li>
                                </ul>
                                <h3 className='phone-nav-title'>Legal & Policies</h3>
                                <ul className='phone-nav-items'>
                                    <li><a href="https://biblophile.com/policies/terms-of-service.php"><FaShieldAlt size={20} /> Terms of Services</a></li>
                                    <li><a href="https://biblophile.com/policies/privacy-policy.php"><FaLock size={20} /> Privacy Policy</a></li>
                                    <li><a href="https://biblophile.com/policies/refund.php"><FaUnlockAlt size={20} /> Return/Refund Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        )
    } 
}

export default Nav;