const API_URL = process.env.REACT_APP_API_URL;

const requests = {
    //request to biblophile actions api
    placeOrder: `${API_URL}?action=placeOrder`,
    userLogin: `${API_URL}?action=userLogin`,
    userSignup: `${API_URL}?action=userSignup`,
    forgotPassword: `${API_URL}?action=forgotPassword`,
    fetchUserData: `${API_URL}?action=fetchUserData`,
    fetchOrders: `${API_URL}?action=fetchOrders`,
    updateUserData: `${API_URL}?action=updateUserData`,
    fetchDeposit: `${API_URL}?action=fetchDeposit`,
    getBookGenre:`${API_URL}?action=getBookGenre`,
    getBooks: `${API_URL}?action=getBooks&bookGenre=`,
    searchBooks: `${API_URL}?action=searchBooks&searchQuery=`,
    searchExternalBooks: `${API_URL}?action=searchExternalBooks&searchQuery=`,
    getBookmarks: `${API_URL}?action=getBookmarks`,
    fetchProductDetails:`${API_URL}?action=fetchProductDetails&id=`,
    fetchExternalBookDetails:`${API_URL}?action=fetchExternalBookDetails&id=`,
    fetchAverageRating:`${API_URL}?action=fetchAverageRating&id=`,
    fetchAverageEmotions:`${API_URL}?action=fetchAverageEmotions&id=`,
    fetchProductReviews:`${API_URL}?action=fetchProductReviews&id=`,
    fetchEmotions:`${API_URL}?action=fetchEmotions`,
    fetchReadingStatus:`${API_URL}?action=fetchReadingStatus`,
    fetchUserBooks: `${API_URL}?action=fetchUserBooks`,
    fetchCurrentReads: `${API_URL}?action=fetchCurrentReads`,
    updateBookDates: `${API_URL}?action=updateBookDates`,
    submitReadingStatus: `${API_URL}?action=submitReadingStatus`,
    submitReview: `${API_URL}?action=submitReview`,
    addBook: `${API_URL}?action=addBook`,
    fetchBookId:`${API_URL}?action=fetchBookId`,
    submitEmotionScore: `${API_URL}?action=submitEmotionScore`,
    fetchActivePlan: `${API_URL}?action=fetchActivePlan&userId=`,
    fetchSubscriptionPlans: `${API_URL}?action=fetchSubscriptionPlans`,
    paymentRequest: `${API_URL}?action=paymentRequest`,
    paymentSuccessful: `${API_URL}?action=paymentSuccessful&linkId=`,

    //request to biblophile user-insights api
    fetchAverageRatingByUser:`/userInsights.php?action=fetchAverageRatingByUser&userId=`,
    fetchAverageEmotionsByUser:`/userInsights.php?action=fetchAverageEmotionsByUser&userId=`,
    fetchAverageDaystoFinish: `/userInsights.php?action=fetchAverageDaystoFinish&userId=`,
    fetchUserReviews: `/userInsights.php?action=fetchUserReviews&userId=`,
    updateUserReview: `/userInsights.php?action=updateUserReview`,
    fetchBookShelf: `/userInsights.php?action=fetchBookShelf`,
    fetchUserNotes: `/userInsights.php?action=fetchUserNotes&userId=`,
    updateUserNote: `/userInsights.php?action=updateUserNote`,
}

export default requests;